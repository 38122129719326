import { createRouter, createWebHistory } from 'vue-router'
import Login from './components/registration/Login.vue'
import Register from './components/registration/Register.vue'
import PageNotFound from './components/PageNotFound.vue'
import LandingPage from './components/LandingPage.vue'

const routes = [
  {
    path: '/',
    name: 'LandingPage',
    component: LandingPage,
    meta: {
      title: 'Apprendre une nouvelle langue | Learnlist',
      description: "Learnlist vous permet d'apprendre une langue en regardant vos séries préférées. Utilisez des listes de vocabulaire adaptées à chaque épisode pour un apprentissage immersif et interactif.",
      keywords: 'apprendre une langue, apprentissage des langues, séries, vocabulaire, immersion linguistique, apprendre en regardant des séries, français, anglais, espagnol, allemand, italien',
      ogTitle: 'Apprendre une nouvelle langue | Learnlist',
      ogDescription: 'Avec Learnlist, apprenez une langue tout en profitant de vos séries préférées. Des listes de vocabulaire personnalisées pour chaque épisode vous permettent de progresser efficacement.',
      robots: 'index, follow',
      author: 'Learnlist Team',
      ogUrl: 'https://learnlist.fr'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: 'Connexion à votre compte | Learnlist',
      description: "Connectez-vous à votre compte Learnlist pour accéder à vos listes de vocabulaire personnalisées et suivre vos progrès dans l'apprentissage des langues.",
      ogTitle: 'Connexion à Learnlist',
      ogDescription: 'Connectez-vous à votre compte Learnlist pour accéder à vos listes de vocabulaire et progresser dans votre apprentissage linguistique.',
      ogUrl: 'https://learnlist.fr/login',
      keywords: 'Connexion, Login, Learnlist, Apprentissage des langues, Vocabulaire',
      robots: 'index, follow',
      author: 'Learnlist Team'
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      title: 'Inscription à Learnlist | Rejoignez-nous',
      description: 'Créez un compte gratuit sur Learnlist pour apprendre une nouvelle langue en utilisant des listes de vocabulaire basées sur vos séries préférées.',
      ogTitle: 'Inscription à Learnlist',
      ogDescription: "Rejoignez Learnlist dès aujourd'hui pour apprendre une nouvelle langue avec des listes de vocabulaire basées sur les séries que vous aimez.",
      ogUrl: 'https://learnlist.fr/register',
      keywords: 'Inscription, Register, Learnlist, Apprentissage des langues, Vocabulaire, Créer un compte',
      robots: 'index, follow',
      author: 'Learnlist Team'
    }
  },
  {
    path: '/edu/register-teacher',
    name: 'RegisterTeacher',
    component: () => import('./components/edu/RegisterTeacher.vue'),
  },
  {
    path: '/edu/register-student',
    name: 'RegisterStudent',
    component: () => import('./components/edu/RegisterStudent.vue'),
  },
  {
    path: '/edu/select-role-edu',
    name: 'SelectRoleEducation',
    component: () => import('./components/edu/SelectRoleEducation.vue'),
  },
  {
    path: '/edu/dashboard-teacher',
    name: 'DashboardTeacher',
    component: () => import('./views/DashboardTeacher.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/verify-email',
    name: 'VerifyEmail',
    component: () => import('./components/registration/VerifyEmail.vue'),
  },
  {
    path: '/resend-verification',
    name: 'ResendVerification',
    component: () => import('./components/registration/ResendVerification.vue'),
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('./components/registration/ForgotPassword.vue'),
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import('./components/registration/ResetPassword.vue'),
  },
  {
    path: '/politique-de-confidentialite',
    name: 'PolitiqueDeConfidentialite',
    component: () => import('./components/PolitiqueDeConfidentialite.vue'),
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: () => import('./components/Feedback.vue'),
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('./views/Home.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/creer-liste',
    name: 'CréerListe',
    component: () => import('./components/CreateList.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/modifier-liste/:id',
    name: 'ModifierListe',
    component: () => import('./components/ModifyList.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/select-lists',
    name: 'SelectLists',
    component: () => import('./components/SelectLists.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/classique-mode',
    name: 'ClassiqueMode',
    component: () => import('./components/ClassiqueMode.vue'),
    meta: { requiresAuth: true }
  },
  {
  path: '/collaborative-lists',
  name : 'CollaborativeLists',
  component: () => import('./components/CollaborativeLists.vue'),
  meta: { requiresAuth: true }
  },
  {
    path: '/view-list/:id',
    name: 'ViewList',
    component: () => import('./components/ViewList.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/leaderboard',
    name: 'Leaderboard',
    component: () => import('./components/Leaderboard.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import('./views/Profile.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/multijoueur/challenge/:listId',
    name: 'FriendChallenge',
    component: () => import('./components/multijoueur/FriendChallenge.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/multijoueur/challenge-summary/:sessionId',
    name: 'ChallengeSummary',
    component: () => import('./components/multijoueur/ChallengeSummary.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/multijoueur/revision-session/:sessionId',
    name: 'RevisionSession',
    component: () => import('./components/multijoueur/RevisionSession.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/multijoueur/waiting-for-acceptance/:invitationId',
    name: 'WaitingForAcceptance',
    component: () => import('./components/multijoueur/WaitingForAcceptance.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/multijoueur/end-session/:sessionId',
    name: 'EndSession',
    component: () => import('./components/multijoueur/EndSession.vue'),
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path: '/mes-badges',
    name: 'MesBadges',
    component: () => import('./components/MesBadges.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/chrono-mode',
    name: 'ChronoMode',
    component: () => import('./components/ChronoMode.vue'),
    meta: { requiresAuth: true }
  },
    {
    path: '/select-mode',
    name: 'SelectMode',
    component: () => import('./components/SelectMode.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/:pathMatch(.*)*', 
    component: PageNotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// Fonction utilitaire pour mettre à jour les balises meta
function updateMeta(metaName, value, property = 'name') {
  let meta = document.querySelector(`meta[${property}="${metaName}"]`);
  if (meta) {
    meta.setAttribute('content', value || '');
  } else {
    meta = document.createElement('meta');
    meta.setAttribute(property, metaName);
    meta.setAttribute('content', value || '');
    document.head.appendChild(meta);
  }
}

// Garde de navigation pour les balises meta et l'authentification
router.beforeEach((to, from, next) => {
  // Mise à jour du titre
  document.title = to.meta.title || 'Learnlist';

  // Mise à jour des balises meta
  updateMeta('description', to.meta.description);
  updateMeta('keywords', to.meta.keywords);
  updateMeta('robots', to.meta.robots, 'name');
  updateMeta('author', to.meta.author, 'name');
  updateMeta('og:title', to.meta.ogTitle, 'property');
  updateMeta('og:description', to.meta.ogDescription, 'property');
  updateMeta('og:url', to.meta.ogUrl, 'property');

  // Authentification
  const isAuthenticated = !!localStorage.getItem('token');
  if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
    next('/login');
  } else {
    next();
  }
});

export default router;
