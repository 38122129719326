<template>
  <div class="login-page">
    <div class="header">
      <v-btn icon @click="goToHome" color="white">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </div>
    <div class="login-container">
      <h2>Connexion</h2>
      <v-form v-model="valid" @submit.prevent="login" class="login-form">
        <v-text-field
          v-model="email"
          label="Adresse e-mail"
          required
          outlined
          color="red"
          class="custom-input"
          :rules="[rules.required, rules.email]"
        ></v-text-field>

        <v-text-field
          :type="passwordFieldType"
          v-model="password"
          label="Mot de passe"
          required
          outlined
          color="red"
          class="custom-input"
          :append-inner-icon="passwordFieldType === 'password' ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append-inner="togglePasswordVisibility"
          :rules="[rules.required]"
        ></v-text-field>

        <v-btn
          :disabled="!valid"
          type="submit"
          color="red"
          class="login-button"
          block
        >
          Se connecter
        </v-btn>
      </v-form>

      <div class="forgot-password">
        <router-link to="/forgot-password">Mot de passe oublié ?</router-link>
      </div>

      <div class="redirect-register">
        <p>
          Pas encore de compte ?
          <router-link to="/register">Inscrivez-vous ici</router-link>
        </p>
      </div>

      <v-alert v-if="errorMessage" variant="outlined" type="error" class="error-message">
        {{ errorMessage }}
      </v-alert>
      <v-alert v-if="sessionExpired" variant="outlined" type="error" class="error-message">
        Votre session a expiré. Veuillez vous reconnecter.
      </v-alert>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data() {
    return {
      email: '',
      password: '',
      passwordFieldType: 'password',
      errorMessage: null,
      sessionExpired: false,
      valid: false,
      rules: {
        required: value => !!value || 'Ce champ est requis.',
        email: value => {
          const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          return pattern.test(value) || 'Adresse e-mail invalide.';
        },
      },
    };
  },
  computed: {
    ...mapState(['loading']),
  },
  created() {
    this.checkSessionExpired();
  },
  methods: {
    togglePasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    },
    async login() {
      if (!this.valid) return;

      try {
        // Appel de l'action Vuex pour la connexion
        await this.$store.dispatch('login', { email: this.email.toLowerCase(), password: this.password });
        this.$router.push('/home'); // Redirige après connexion réussie
      } catch (error) {
        // Vérifiez si l'erreur est une réponse du serveur
        if (error.status === 403) {
          this.errorMessage = 'Veuillez vérifier votre e-mail avant de vous connecter.';
        } else if (error.status === 400) {
          this.errorMessage = 'Les identifiants renseignés sont incorrects.';
        } else if (error.status === 500) {
          this.errorMessage = 'Une erreur interne est survenue. Veuillez réessayer plus tard.';
        } else {
          this.errorMessage = 'Erreur lors de la connexion. Veuillez réessayer.';
        }

        // Log de l'erreur pour un débogage plus facile
        console.error('Erreur lors de la connexion (frontend) :', error.message);
      }
    },
    goToHome() {
      this.$router.push('/');
    },
    checkSessionExpired() {
      if (localStorage.getItem('sessionExpired') === 'true') {
        this.sessionExpired = true;
        localStorage.removeItem('sessionExpired'); // Nettoyer après l'affichage
      }
    }
  },
};
</script>

<style scoped>
/* Main page styling */
.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #141414;
  color: #ffffff;
  position: relative;
}

.header {
  position: absolute;
  top: 10px;
  left: 10px;
}

.login-container {
  background-color: rgba(0, 0, 0, 0.75);
  padding: 40px 60px;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
  width: 400px;
  max-width: 90%;
  text-align: center;
}

h2 {
  font-size: 28px;
  margin-bottom: 20px;
  font-weight: bold;
}

.login-form {
  display: flex;
  flex-direction: column;
}

.custom-input {
  margin-bottom: 20px;
  width: 100%;
}

.login-button {
  padding: 12px;
  border-radius: 4px;
  border: none;
  background-color: #e50914;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: #f40612;
}

.redirect-register p {
  color: #b3b3b3;
  margin-top: 20px;
}

.redirect-register p a {
  color: #b3b3b3;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.redirect-register p a:hover {
  color: #ffffff;
}

.forgot-password {
  margin-top: 10px;
  color: #b3b3b3;
  text-align: center;
}

.forgot-password a {
  color: #b3b3b3;
  text-decoration: none;
  font-weight: bold;
}

.forgot-password a:hover {
  color: #ffffff;
}

.error-message {
  color: #e50914;
  margin-top: 20px;
  font-size: 14px;
}

/* Responsive Design */
@media (max-width: 600px) {
  .login-container {
    padding: 30px;
    width: 90%;
  }

  .login-button {
    font-size: 14px;
  }

  h2 {
    font-size: 24px;
  }
}
</style>
