<template>
    <v-container class="page-not-found" fluid>
      <v-row justify="center" align="center">
        <v-col cols="12" md="6">
          <v-card class="pa-5" outlined>
            <v-card-title>
              <h1 class="display-1">404</h1>
            </v-card-title>
            <v-card-text>
              <p class="headline">Désolé, la page que vous cherchez n'existe pas.</p>
              <v-btn color="primary" @click="$router.push('/')">Retour à l'accueil</v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
</template>
  
<script>
  export default {
    name: 'PageNotFound'
  }
  </script>
  
  <style scoped>
  .page-not-found {
    background-color: #141414;
    color: #333;
    min-height: 100vh;
    text-align: center;
  }
  
  .v-card {
    max-width: 600px;
    margin: auto;
  }
  
  .display-1 {
    color: #e50914;
    font-weight: bold;
  }
  
  .headline {
    margin-bottom: 20px;
  }
</style>