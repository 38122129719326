<template>
  <v-app>
    <!-- Header with Login Icon -->
    <v-row align="center" justify="end" class="login-icon-section">
      <v-col cols="auto">
        <v-btn icon @click="navigateToLogin" color="white">
          <v-icon>mdi-login</v-icon>
        </v-btn>
      </v-col>
    </v-row>

    <!-- Main Content -->
    <v-main>
      <!-- Hero Section -->
      <v-container fluid class="hero-section" fill-height>
        <!-- Logo Section -->
        <v-row align="center" justify="center" class="logo-section">
          <v-col cols="12" class="text-center">
            <h2 class="logo">LEARNLIST</h2>
          </v-col>
        </v-row>

        <!-- Main Hero Content -->
        <v-row align="center" justify="center" class="hero-content">
          <v-col cols="12" sm="8" class="text-center">
            <h1 class="hero-title">Apprenez une nouvelle langue en regardant vos séries préférées</h1>
            <p class="hero-subtitle">Utilisez notre méthode unique pour apprendre une langue grâce à des listes de
              vocabulaire basées sur les séries que vous aimez.</p>
            <v-btn outlined color="white" large @click="navigateToApp" elevation="15" class="cta-button">
              Commencer maintenant
            </v-btn>
            <!-- Add to Home Screen Button for iOS -->
            <v-btn v-if="isIOS && !isInStandalone" outlined color="#ecfad0" small @click="showIOSInstructions" class="add-to-home-button">
              <v-icon left>mdi-apple</v-icon> Ajouter à l'écran d'accueil
            </v-btn>

            <!-- Add to Home Screen Button for Android -->
            <v-btn v-if="isAndroid && deferredPrompt" outlined color="#ecfad0" large @click="addToHomeScreen"
              class="add-to-home-button">
              <v-icon left>mdi-cellphone-arrow-down</v-icon> Installer l'application
            </v-btn>
          </v-col>
        </v-row>

        <!-- Parallax Images with Lazy Loading -->
        <v-img src="../assets/images/cin3.webp" lazy-src="../assets/images/cin3.webp" class="parallax-image reel-image"
          alt="Film Reel" />
        <v-img src="../assets/images/cin8.webp" lazy-src="../assets/images/cin8.webp"
          class="parallax-image popcorn-image" alt="Popcorn" />
      </v-container>

      <!-- Section Features -->
      <v-container fluid class="features-section">
        <v-lazy :options="{'threshold':0.5}" transition="fade-transition">
          <v-row justify="center">
            <v-col cols="12" sm="4" class="text-center">
              <v-icon size="64" color="red" class="feature-icon">mdi-book-open-variant</v-icon>
              <h3>Apprentissage immersif</h3>
              <p>Plongez-vous dans la langue que vous apprenez en regardant vos séries préférées avec des listes de
                vocabulaire intégrées.</p>
            </v-col>
            <v-col cols="12" sm="4" class="text-center">
              <v-icon size="64" color="red" class="feature-icon">mdi-television-classic</v-icon>
              <h3>Séries populaires</h3>
              <p>Nous proposons des listes de vocabulaire basées sur les séries les plus populaires pour rendre
                l'apprentissage plus amusant et efficace.</p>
            </v-col>
            <v-col cols="12" sm="4" class="text-center">
              <v-icon size="64" color="red" class="feature-icon">mdi-account-multiple</v-icon>
              <h3>Communauté</h3>
              <p>Rejoignez une communauté d'apprenants partageant les mêmes intérêts, partagez vos listes de vocabulaire
                et progressez ensemble.</p>
            </v-col>
          </v-row>
        </v-lazy>
      </v-container>

      <!-- Section Roadmap -->
      <v-container fluid class="roadmap-section">
        <v-row justify="center">
          <v-col cols="12" class="text-center">
            <h2 class="roadmap-title">Votre Parcours d'Apprentissage</h2>
          </v-col>
        </v-row>
        <v-row justify="center" class="roadmap">
          <v-col cols="12" sm="4" class="text-center roadmap-step">
            <v-img src="@/assets/images/etape1.webp" lazy-src="@/assets/images/etape1.webp" alt="Étape 1"
              class="step-image" rounded></v-img>
            <h3 class="step-title">Étape 1</h3>
            <p class="step-description">Regardez votre série et prenez des notes sur les mots à apprendre.</p>
          </v-col>
          <v-col cols="12" sm="4" class="text-center roadmap-step">
            <v-img src="../assets/images/etape2.webp" lazy-src="@/assets/images/etape2.webp" alt="Étape 2"
              class="step-image" rounded></v-img>
            <h3 class="step-title">Étape 2</h3>
            <p class="step-description">Renseignez votre liste de mots sur la plateforme.</p>
          </v-col>
          <v-col cols="12" sm="4" class="text-center roadmap-step">
            <v-img src="../assets/images/etape3.webp" lazy-src="@/assets/images/etape3.webp" alt="Étape 3"
              class="step-image" rounded></v-img>
            <h3 class="step-title">Étape 3</h3>
            <p class="step-description">Collaborez avec d'autres passionnés pour apprendre les mots de leurs listes.</p>
          </v-col>
        </v-row>
      </v-container>
      <v-divider></v-divider>
      <v-container fluid class="modes-section py-5">
        <v-row justify="center">
          <v-col cols="12" class="text-center white--text mb-5">
            <h2 class="modes-title">Les Modes de Révision</h2>
          </v-col>
        </v-row>
        <v-row justify="center" class="gap-4">
          <!-- Mode Classique -->
          <v-col cols="12" sm="5" class="text-center mode-card">
            <v-card outlined class="hoverable elevation-2" color="transparent">
              <v-img src="@/assets/images/classique-mode.webp" class="iphone-image" alt="Mode Classique Image"
                lazy-src="@/assets/images/classique-mode.webp" aspect-ratio="9/16" contain></v-img>
              <v-card-title class="white--text mt-4">Mode Classique</v-card-title>
              <v-card-text>
                <p class="white--text">Testez votre mémoire en révisant chaque mot individuellement. Trouvez la bonne
                  traduction pour renforcer votre apprentissage.</p>
              </v-card-text>
            </v-card>
          </v-col>

          <!-- Mode Chrono -->
          <v-col cols="12" sm="5" class="text-center mode-card">
            <v-card outlined class="hoverable elevation-2" color="transparent">
              <v-img src="@/assets/images/chrono-mode.webp" class="iphone-image" alt="Mode Chrono Image"
                lazy-src="@/assets/images/chrono-mode.webp" aspect-ratio="9/16" contain></v-img>
              <v-card-title class="white--text mt-4">Mode Chrono</v-card-title>
              <v-card-text>
                <p class="white--text">Affrontez le chronomètre pour traduire un maximum de mots dans un temps limité.
                  Un défi parfait pour booster votre apprentissage !</p>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-divider></v-divider>
      <!-- Section Storytelling -->
      <v-container fluid class="storytelling-section">
        <v-row justify="center">
          <v-col cols="12" sm="10" md="8" class="text-center">
            <h2 class="storytelling-title">Notre Histoire</h2>
            <p class="storytelling-content">
              Tout a commencé lors de notre retour d'un voyage dans un pays <strong class="keyword">anglophone</strong>.
              Déterminés à améliorer notre <strong class="keyword">anglais</strong>, nous avons commencé à regarder nos
              <strong class="keyword">séries</strong> préférées d'une manière nouvelle : en notant chaque mot et
              expression qui nous semblait utile pour enrichir notre <strong class="keyword">vocabulaire</strong>. Mais
              très vite, ces listes se sont perdues sur nos téléphones.
            </p>
            <p class="storytelling-content">
              Nous avons alors compris qu'il nous manquait une méthode pour <strong class="keyword">organiser</strong>
              et <strong class="keyword">réviser</strong> efficacement ces mots, et pour partager nos découvertes avec
              d'autres passionnés. C'est ainsi que <strong class="keyword">Learnlist</strong> est né. Nous avons conçu
              une plateforme qui permet non seulement de <strong class="keyword">créer</strong> et <strong
                class="keyword">gérer</strong> vos listes de vocabulaire, mais aussi de les <strong
                class="keyword">partager</strong> avec une <strong class="keyword">communauté</strong> d'apprenants
              motivés. Ensemble, nous apprenons mieux et plus vite.
            </p>
          </v-col>
        </v-row>
        <v-img src="../assets/images/airplane2.webp" lazy-src="../assets/images/airplane2.webp"
          class="parallax-image airplane-image" alt="airplane" />
        <v-img src="../assets/images/creativity-hat2.webp" lazy-src="../assets/images/creativity-hat2.webp"
          class="parallax-image idea-image" alt="idea" />
      </v-container>
      <v-divider></v-divider>
      <!-- Section Call to Action -->
      <v-container fluid class="cta-section text-center">
        <h2>Prêt à commencer?</h2>
        <v-btn outlined color="white" large @click="navigateToApp" elevation="15" class="cta-button">
          Créer un compte gratuit
        </v-btn>
      </v-container>
    </v-main>

    <!-- Footer -->
    <v-footer app color="black" dark>
      <v-container>
        <v-row justify="center">
          <!-- Lien vers la politique de confidentialité -->
          <a href="/politique-de-confidentialite" class="privacy-link">Politique de confidentialité</a>
        </v-row>
        <v-row justify="center" class="mt-2">
          <!-- Icône Instagram -->
          <a href="https://www.instagram.com/learnlist.fr/" target="_blank" class="ml-2">
            <v-icon color="white">mdi-instagram</v-icon>
          </a>
        </v-row>
        <v-row justify="center" class="mt-2">
          <span>&copy; {{ currentYear }} - Learnlist</span>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: "LandingPage",
  data() {
    return {
      currentYear: new Date().getFullYear(),
      isIOS: false,
      isAndroid: false,
      isInStandalone: false,
      deferredPrompt: null
    };
  },
  created() {
    // Detect if the user is on iOS
    const userAgent = window.navigator.userAgent.toLowerCase();
    this.isIOS = /iphone|ipad|ipod/.test(userAgent) && /safari/.test(userAgent);
    this.isInStandalone = window.navigator.standalone;
    // Detect if the user is on Android and use the 'beforeinstallprompt' event
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();  // Prevent the automatic prompt
      this.deferredPrompt = e;  // Store the event for later
      this.isAndroid = true;  // Show the Android button
    });
  },
  methods: {
    navigateToApp() {
      this.$router.push('/register');
    },
    navigateToLogin() {
      this.$router.push('/login');
    },
    showIOSInstructions() {
      alert("Pour ajouter cette application à votre écran d'accueil, ouvrez le menu de partage dans Safari et sélectionnez 'Ajouter à l'écran d'accueil'.");
    },
    addToHomeScreen() {
      if (this.deferredPrompt) {
        this.deferredPrompt.prompt();  // Show the prompt
        this.deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the A2HS prompt');
          } else {
            console.log('User dismissed the A2HS prompt');
          }
          this.deferredPrompt = null;  // Reset the deferred prompt
        });
      }
    }
  }
};
</script>

<style scoped>
/* Header with Login Icon */
.login-icon-section {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 10;
}

/* Hero Section */
.hero-section {
  background-image: url('../assets/images/background-min.webp');
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  padding: 0 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.logo-section {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.logo {
  font-size: 2.5rem;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  animation: fadeIn 1.5s ease-in-out;
}

.hero-content {
  z-index: 1;
}

.hero-title {
  font-size: 2.8rem;
  font-weight: bold;
  margin-bottom: 15px;
  color: white;
  animation: fadeInDown 1s ease-out;
}

.hero-subtitle {
  font-size: 1.4rem;
  margin-bottom: 20px;
  color: white;
  animation: fadeInUp 1s ease-out;
}

.cta-button {
  font-size: 1.1rem;
  font-weight: bold;
  padding: 10px 20px;
  margin-top: 15px;
  color: white;
  border-color: white;
  animation: pulse 2s infinite;
}

.add-to-home-button {
  margin-top: 20px;
  font-size: 0.8rem;
}

/* Parallax Images */
.parallax-image {
  position: absolute;
  object-fit: contain;
  animation: float 3s infinite ease-in-out;
}

.reel-image {
  top: 15%;
  left: 0;
  width: 150px;
}

.popcorn-image {
  bottom: 10%;
  right: 0;
  width: 150px;
}

/* Features Section */
.features-section {
  background-color: #1f1f1f;
  padding: 60px 20px;
  color: white;
  text-align: center;
}

.feature-icon {
  transition: transform 0.3s ease;
  animation: fadeInUp 1s ease-out;
}

.feature-icon:hover {
  transform: scale(1.2);
}

/* Roadmap Section */
.roadmap-section {
  background-color: #141414;
  padding: 60px 20px;
  color: white;
  text-align: center;
  position: relative;
}

.roadmap {
  position: relative;
}

.roadmap-step {
  position: relative;
  margin-bottom: 60px;
}

.step-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.step-title {
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.step-description {
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.modes-section {
  background-color: #141414; /* Assurer le fond sombre */
  padding: 40px 20px; /* Ajouter du padding pour respirer */
}

.mode-card {
  background-color: transparent; /* Rendre le fond de la carte transparent */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-radius: 15px; /* Arrondir les coins pour un rendu esthétique */
}

.iphone-image {
  max-width: 40%; /* Ajuster la taille de l'image */
  height: auto;
  margin: 20px auto;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4); /* Ajouter une légère ombre */
}

.white--text {
  color: #ffffff !important; /* Assurer que le texte soit bien en blanc */
}

/* Call to Action Section */
.cta-section {
  background-color: #141414;
  padding: 70px 20px;
  color: white;
}

/* Section Storytelling */
.storytelling-section {
  background-color: #141414; /* Fond sombre */
  padding: 60px 20px;
  color: #fff; /* Texte en blanc */
  text-align: center;
  position: relative;
}

.storytelling-title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 30px;
  color: #eee; 
}

.storytelling-content {
  font-size: 1.2rem;
  line-height: 1.8;
  color: #eee;
  max-width: 800px;
  margin: 0 auto 30px auto; /* Espace autour des paragraphes */
}

.keyword {
  font-weight: bold;
  color: #D32F2F; /* Rouge plus doux, sans soulignement */
}

/* Espacement entre les paragraphes */
.storytelling-content + .storytelling-content {
  margin-top: 40px;
}

.airplane-image {
  bottom: 10%; /* En bas */
  left: 2%; /* À droite */
  width: 150px; /* Ajuste la taille selon tes préférences */
  opacity: 0.8;
}

.idea-image {
  top: 15%; /* En haut */
  right: 2%; /* À gauche */
  width: 150px; /* Ajuste la taille selon tes préférences */
  opacity: 0.8;
}

.v-footer {
  padding: 20px;
  text-align: center;
}
.privacy-link {
  color: whitesmoke;
  text-decoration: none;
  cursor: pointer;
  margin-bottom: 5px;
}

.privacy-link:hover {
  color: white;
}

/* Responsive Design */
@media (max-width: 600px) {
  .hero-title {
    font-size: 2.2rem;
  }
  .hero-subtitle {
    font-size: 1.2rem;
  }
  .logo {
    font-size: 2rem;
  }
  .parallax-image {
    width: 100px;
  }
  .step-title {
    font-size: 1.3rem;
  }
  .step-description {
    font-size: 1rem;
  }
  .airplane-image {
    bottom: 0;
  }
  .idea-image {
    top: 0;
  }
}

/* Animations */
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes pulse {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.02);
  }
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
</style>
