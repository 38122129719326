<template>
  <v-app-bar app color="black" dark elevate-on-scroll clipped-left>
    <!-- Logo and Toggle Button -->
    <v-toolbar-title @click="goBackHome" class="navbar-logo">
      Learn<span style="color: #e50914;">list</span>
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <!-- Menu for Large Screens -->
    <v-btn
      v-if="isAuthenticated"
      text
      @click="navigateToCreate"
      class="mx-2 d-none d-md-flex"
    >
      Créer une liste
    </v-btn>
    <v-btn
      v-if="isAuthenticated"
      text
      @click="navigateToRevise"
      class="mx-2 d-none d-md-flex"
    >
      Réviser
    </v-btn>
    <v-btn
      v-if="isAuthenticated"
      text
      @click="navigateToCollaborativeLists"
      class="mx-2 d-none d-md-flex"
    >
      Plateforme
    </v-btn>
    <v-btn
      v-if="isAuthenticated"
      text
      @click="navigateToLeaderboard"
      class="mx-2 d-none d-md-flex"
    >
      Leaderboard
    </v-btn>

    <!-- Afficher le badge de notification seulement si notificationCount > 0 -->
    <v-badge
      v-if="notificationCount > 0"
      color="red"
      :content="notificationCount"
      overlap
      max="99"
    >
      <v-avatar
        color="red darken-1"
        size="40"
        @click="goToProfile"
        class="mx-2 d-none d-md-flex"
      >
        {{ userInitial }}
      </v-avatar>
    </v-badge>

    <!-- Afficher uniquement l'avatar si notificationCount est égal à 0 -->
    <v-avatar
      v-else
      color="red darken-1"
      size="40"
      @click="goToProfile"
      class="mx-2 d-none d-md-flex"
    >
      {{ userInitial }}
    </v-avatar>

    <v-btn
      v-if="isAuthenticated"
      icon
      @click="logout"
      class="logout-button d-none d-md-flex"
    >
      <v-icon>mdi-logout</v-icon>
    </v-btn>

    <!-- Mobile Menu Toggle -->
    <v-app-bar-nav-icon
      v-if="isAuthenticated"
      @click="menuOpen = !menuOpen"
      class="d-md-none"
    ></v-app-bar-nav-icon>
  </v-app-bar>

  <!-- Mobile Navigation Drawer -->
  <v-navigation-drawer
    v-model="menuOpen"
    app
    temporary
    right
    width="250"
    color="black"
  >
    <v-list>
      <v-list-item @click="navigateToCreate">
        <v-list-item-title>Créer une liste</v-list-item-title>
      </v-list-item>
      <v-list-item @click="navigateToRevise">
        <v-list-item-title>Réviser</v-list-item-title>
      </v-list-item>
      <v-list-item @click="navigateToCollaborativeLists">
        <v-list-item-title>Plateforme</v-list-item-title>
      </v-list-item>
      <v-list-item @click="navigateToLeaderboard">
        <v-list-item-title>Leaderboard</v-list-item-title>
      </v-list-item>
      <v-list-item @click="goToProfile">
        <v-list-item-title>Mon profil</v-list-item-title>
      </v-list-item>
      <v-list-item @click="logout">
        <v-list-item-title>Se déconnecter</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'NavBar',
  data() {
    return {
      menuOpen: false,
      notificationCount: 0, // Nombre de notifications
    };
  },
  computed: {
    ...mapState(['isAuthenticated']),
    userInitial() {
      const username = localStorage.getItem('username');
      return username ? username.charAt(0).toUpperCase() : 'A';
    },
  },
  mounted() {
    if (this.isAuthenticated) {
      this.fetchNotificationCount();
    }
  },
  methods: {
    async fetchNotificationCount() {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch('https://api.learnlist.fr/notifications/count', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.ok) {
          const data = await response.json();
          this.notificationCount = data.count;
        } else {
          console.error('Erreur lors de la récupération du nombre de notifications:', response.statusText);
        }
      } catch (error) {
        console.error('Erreur lors de la récupération du nombre de notifications:', error);
      }
    },
    navigateToCreate() {
      this.$router.push('/creer-liste');
      this.menuOpen = false;
    },
    navigateToRevise() {
      this.$router.push('/select-lists');
      this.menuOpen = false;
    },
    navigateToCollaborativeLists() {
      this.$router.push('/collaborative-lists');
      this.menuOpen = false;
    },
    navigateToLeaderboard() {
      this.$router.push('/leaderboard');
      this.menuOpen = false;
    },
    goBackHome() {
      this.$router.push('/home');
      this.menuOpen = false;
    },
    goToProfile() {
      this.$router.push('/profile');
      this.menuOpen = false;
    },
    logout() {
      this.$store.dispatch('logout');
      this.menuOpen = false;
    },
  },
};
</script>

<style scoped>
.navbar-logo {
  font-size: 1.8rem;
  font-weight: bold;
  cursor: pointer;
}

.logout-button {
  color: #ffffff;
}

.v-avatar {
  cursor: pointer;
}

.v-navigation-drawer {
  background-color: #141414;
  color: #ffffff;
}

.v-list-item-title {
  font-weight: bold;
  color: #ffffff;
}

.v-list-item:hover {
  background-color: rgba(229, 9, 20, 0.5);
}

@media (max-width: 600px) {
  .navbar-logo {
    font-size: 1.6rem;
  }
}
</style>
