import { createStore } from 'vuex';
import { jwtDecode } from 'jwt-decode';

export default createStore({
  state: {
    isAuthenticated: !!localStorage.getItem('token'),
    loading: false,
    fromCollaborative: false,
    badges: [],
    userRole: null,
  },
  mutations: {
    setAuthenticated(state, status) {
      state.isAuthenticated = status;
      console.log("isAuthenticated set to:", status);
    },
    setLoading(state, status) {
      state.loading = status;
      console.log("Loading status set to:", status);
    },
    setFromCollaborative(state, status) {
      state.fromCollaborative = status;
    },
    setBadges(state, badges) {
      state.badges = badges;
    },
    addBadge(state, badge) {
      state.badges.push(badge);
    },
    setUserRole(state, role) {
      state.userRole = role;
      console.log("User role set to:", role); // Log pour vérifier la mise à jour du rôle
    },
  },
  actions: {
    async login({ commit, dispatch }, credentials) {
      commit('setLoading', true);
      try {
        const response = await fetch('https://api.learnlist.fr/auth/login', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(credentials),
        });
        const data = await response.json();

        if (!response.ok) {
          const error = new Error(data.error || 'Erreur lors de la connexion');
          error.status = response.status;
          throw error;
        }

        if (data.token) {
          localStorage.setItem('token', data.token);
          localStorage.setItem('username', data.username);
          localStorage.setItem('streak', data.streak || 0);

          const decodedToken = jwtDecode(data.token);
          const expiryTime = decodedToken.exp * 1000;
          const timeToExpiry = expiryTime - Date.now();

          console.log("Decoded token:", decodedToken); // Vérifier le contenu du token décodé

          // Définir le rôle de l'utilisateur
          commit('setUserRole', decodedToken.role); // Assigner le rôle (teacher ou student)

          // Gestion automatique de la déconnexion
          dispatch('autoLogout', timeToExpiry);

          if (data.badges) {
            commit('setBadges', data.badges);
          }

          if (data.streakUpdated) {
            localStorage.setItem('streakUpdated', 'true');
          } else {
            localStorage.removeItem('streakUpdated');
          }
          commit('setAuthenticated', true);
        } else {
          throw new Error('Login failed');
        }
      } catch (error) {
        console.error('Erreur lors de la connexion:', error.message);
        throw error;
      } finally {
        commit('setLoading', false);
      }
    },
    logout({ commit }) {
      localStorage.removeItem('token');
      localStorage.removeItem('username');
      localStorage.removeItem('streak');
      localStorage.removeItem('streakUpdated');
      commit('setAuthenticated', false);
      commit('setBadges', []);
      commit('setUserRole', null); // Réinitialiser le rôle à la déconnexion
      console.log("User logged out, role reset to null"); // Log pour confirmer la déconnexion
    },
    addNewBadge({ commit }, badge) {
      commit('addBadge', badge);
    },
    autoLogout({ dispatch }, timeToExpiry) {
      setTimeout(() => {
        dispatch('logout');
        alert('Votre session a expiré. Veuillez vous reconnecter.');
      }, timeToExpiry);
    },
  },
});
