<template>
  <v-card class="cookie-banner-card" elevation="4">
    <v-card-text align="center">
      Nous utilisons des cookies pour améliorer votre expérience. Acceptez-vous l'utilisation des cookies analytiques ?
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="acceptCookies" color="primary" class="banner-button">
        Accepter
      </v-btn>
      <v-btn @click="declineCookies" color="secondary" class="banner-button">
        Refuser
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'CookieBanner',
  methods: {
    acceptCookies() {
      localStorage.setItem('cookiesAccepted', true);
      this.$emit('cookies-accepted');
    },
    declineCookies() {
      localStorage.setItem('cookiesAccepted', false);
      this.$emit('cookies-declined');
    },
  },
};
</script>

<style scoped>
.cookie-banner-card {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #333;
  color: white;
  z-index: 9999;
}

.banner-button {
  color: white;
  font-weight: bold;
}

.v-btn {
  margin: 0 10px;
}
</style>
