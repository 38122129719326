<template>
  <div class="register-page">
    <div class="register-container">
      <h2>Inscription</h2>
      <v-form @submit.prevent="register" class="register-form">

        <!-- Champ pour le nom d'utilisateur -->
        <v-text-field v-model="username" label="Nom d'utilisateur" required outlined color="red" class="custom-input"
          :rules="[rules.required, rules.username]" @input="validateUsername" ref="usernameField"></v-text-field>

        <!-- Liste des règles de validation pour le nom d'utilisateur -->
        <ul class="validation-list">
          <li :class="{ valid: isValidUsername }">
            <v-icon :color="isValidUsername ? 'green' : 'red'">{{ isValidUsername ? 'mdi-check' : 'mdi-close-circle' }}</v-icon>
            Le username peut contenir des lettres, chiffres, points ou underscores.
          </li>
        </ul>

        <!-- Champ pour l'email -->
        <v-text-field v-model="email" label="Adresse e-mail" required outlined color="red" class="custom-input"
          :rules="[rules.required, rules.email]"></v-text-field>

        <!-- Champ pour le mot de passe -->
        <v-text-field :type="passwordFieldType" v-model="password" label="Mot de passe" required outlined color="red"
          class="custom-input" :append-inner-icon="passwordFieldType === 'password' ? 'mdi-eye' : 'mdi-eye-off'"
          @click:append-inner="togglePasswordVisibility" @input="validatePassword"
          :rules="[rules.required]"></v-text-field>

        <!-- Liste des règles de validation pour le mot de passe -->
        <ul class="validation-list">
          <li :class="{ valid: password.length >= 8 }">
            <v-icon :color="password.length >= 8 ? 'green' : 'red'">{{ password.length >= 8 ? 'mdi-check' : 'mdi-close-circle' }}</v-icon>
            Au moins 8 caractères
          </li>
          <li :class="{ valid: hasUpperCase }">
            <v-icon :color="hasUpperCase ? 'green' : 'red'">{{ hasUpperCase ? 'mdi-check' : 'mdi-close-circle' }}</v-icon>
            Au moins une lettre majuscule
          </li>
          <li :class="{ valid: hasLowerCase }">
            <v-icon :color="hasLowerCase ? 'green' : 'red'">{{ hasLowerCase ? 'mdi-check' : 'mdi-close-circle' }}</v-icon>
            Au moins une lettre minuscule
          </li>
          <li :class="{ valid: hasNumber }">
            <v-icon :color="hasNumber ? 'green' : 'red'">{{ hasNumber ? 'mdi-check' : 'mdi-close-circle' }}</v-icon>
            Au moins un chiffre
          </li>
          <li :class="{ valid: hasSpecialChar }">
            <v-icon :color="hasSpecialChar ? 'green' : 'red'">{{ hasSpecialChar ? 'mdi-check' : 'mdi-close-circle' }}</v-icon>
            Au moins un caractère spécial
          </li>
        </ul>

        <!-- Champ pour confirmer le mot de passe -->
        <v-text-field :type="passwordFieldType" v-model="confirmPassword" label="Confirmer le mot de passe" required
          outlined color="red" class="custom-input" :rules="[rules.required, rules.confirmPassword]"></v-text-field>

        <!-- Bouton d'inscription -->
        <v-btn type="submit" :disabled="!isFormValid" color="red" class="register-button" block :loading="loading">
          S'inscrire
        </v-btn>
      </v-form>

      <div class="redirect-login">
        <p>
          Vous avez déjà un compte ?
          <router-link to="/login">Se connecter</router-link>
        </p>
      </div>
      <v-text class="policy-text" align="center">
        En vous inscrivant, vous acceptez notre
        <router-link to="/politique-de-confidentialite" class="policy-link">politique de confidentialité</router-link>.
      </v-text>

      <v-alert v-if="errorMessage" type="error" class="error-message">
        {{ errorMessage }}
      </v-alert>

      <v-alert v-if="verificationEmailSent" type="success" class="verification-message">
        Un e-mail de vérification a été envoyé à {{ email }}. Veuillez vérifier votre boîte de réception ainsi que les spams.
      </v-alert>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: '',
      email: '',
      password: '',
      confirmPassword: '',
      passwordFieldType: 'password',
      errorMessage: null,
      loading: false,
      verificationEmailSent: false,
      isValidUsername: false,
      hasUpperCase: false,
      hasLowerCase: false,
      hasNumber: false,
      hasSpecialChar: false,
      rules: {
        required: value => !!value || 'Ce champ est requis.',
        email: value => {
          const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          return pattern.test(value) || 'Adresse e-mail invalide.';
        },
        confirmPassword: value => value === this.password || 'Les mots de passe doivent correspondre.',
      },
    };
  },
  computed: {
    isFormValid() {
      return (
        this.isValidUsername &&
        this.rules.email(this.email) === true &&
        this.password.length >= 8 &&
        this.hasUpperCase &&
        this.hasLowerCase &&
        this.hasNumber &&
        this.hasSpecialChar &&
        this.confirmPassword === this.password
      );
    },
  },
  methods: {
    togglePasswordVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    },
    validateUsername() {
      const pattern = /^[a-zA-Z0-9._]+$/;
      this.isValidUsername = pattern.test(this.username.trim()) && this.username.length >= 3;
    },
    validatePassword() {
      this.hasUpperCase = /[A-Z]/.test(this.password);
      this.hasLowerCase = /[a-z]/.test(this.password);
      this.hasNumber = /[0-9]/.test(this.password);
      this.hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(this.password);
    },
    async register() {
      if (!this.isFormValid) return; // Empêche l'appel à l'API si le formulaire n'est pas valide

      this.loading = true;
      this.errorMessage = null;
      this.verificationEmailSent = false;

      try {
        const response = await fetch('https://api.learnlist.fr/auth/register', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            username: this.username.toLowerCase(),
            email: this.email.toLowerCase(),
            password: this.password,
          }),
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.error || "Erreur lors de l'inscription");
        }

        this.verificationEmailSent = true;
        setTimeout(() => {
          this.$router.push('/login');
        }, 8000);
      } catch (error) {
        this.errorMessage = error.message;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>


<style scoped>
.register-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #141414;
  color: #ffffff;
}

.register-container {
  background-color: rgba(0, 0, 0, 0.75);
  padding: 40px 60px;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
  width: 400px;
  max-width: 90%;
  text-align: left;
}

h2 {
  font-size: 28px;
  margin-bottom: 20px;
  font-weight: bold;
}

.register-form {
  display: flex;
  flex-direction: column;
}

.custom-input {
  margin-bottom: 20px;
  width: 100%;
}

.register-button {
  padding: 12px;
  border-radius: 4px;
  border: none;
  background-color: #e50914;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.register-button:hover {
  background-color: #f40612;
}

.redirect-login p {
  color: #b3b3b3;
  margin-top: 20px;
}

.redirect-login p a {
  color: #b3b3b3;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.redirect-login p a:hover {
  color: #ffffff;
}

.error-message {
  color: #e50914;
  margin-top: 20px;
  font-size: 14px;
}

/* Message de vérification de l'e-mail */
.verification-message {
  margin-top: 20px;
  font-size: 16px;
  color: #4caf50;
}

.validation-list {
  list-style: none;
  padding: 0;
  margin: 10px 0;
}

.validation-list li {
  color: #fff;
  display: flex;
  align-items: center;
  font-size: 12px;
}

.validation-list li.valid {
  color: #4caf50;
}

.validation-list li v-icon {
  margin-right: 5px;
}

.policy-text {
  font-size: 0.875rem;
  color: #555;
  margin-top: 1em;
}

.policy-link {
  color: inherit;
  text-decoration: underline;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .register-container {
    padding: 30px;
    width: 90%;
  }

  .register-button {
    font-size: 14px;
  }
  
  h2 {
    font-size: 24px;
  }
}
</style>
