import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import axios from 'axios';
import VueGtag from 'vue-gtag';
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";

axios.defaults.baseURL = 'https://api.learnlist.fr';

// Initialisation de l'application Vue
const app = createApp(App);
app.use(VueGtag, { config: { id: "G-P11Q16R736" } });
app.use(store).use(router).use(vuetify);
app.use(Toast, {
  position: POSITION.TOP_RIGHT,
  timeout: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  closeButton: "button",
  rtl: false,
});

app.mount('#app');

// Enregistrement du Service Worker
if ("serviceWorker" in navigator) {
  window.addEventListener("load", async () => {
    try {
      const registration = await navigator.serviceWorker.register("/firebase-messaging-sw.js");
      console.log("Service Worker enregistré avec succès :", registration);
    } catch (error) {
      console.error("Échec de l'enregistrement du Service Worker :", error);
    }
  });
}
